/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

//This is required to style some of the material controls. Do not remove!
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

//Styling for the Image Editor
@import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-image-editor/styles/material.css";
/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

/* @import "@ionic/angular/css/palettes/dark.always.css"; */
/* @import "@ionic/angular/css/palettes/dark.class.css"; */
//@import "@ionic/angular/css/palettes/dark.system.css";

// Override Ionic/Material Variables
:root {
  --mat-paginator-enabled-icon-color: var(--ion-color-secondary);
}

// this is to make to content page with pagination scrollable
app-sides {
  top: 60px !important;
}

.spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  width: 100px;
  height: 100px;
  z-index: 1001;

  ion-spinner {
    color: var(--ion-color-secondary);
    width: 100px;
    height: 100px;
  }
}

.hr {
  border-bottom: 1px solid #ccc;
  margin: 20px 0;
  width: 100%;
}

.material-table {

  // Hide default arrow stem
  .mat-sort-header-stem {
    display: none;
  }

  .mat-sort-header-indicator {
    opacity: 1;
    color: var(--ion-color-secondary);
    font-weight: bold;
    // Hide default arrow as its composed of left, right and middle
    /*.mat-sort-header-pointer-left,
    .mat-sort-header-pointer-right,
    .mat-sort-header-pointer-middle {
      display: none;
    }*/
  }


  th {
    font-weight: bold;
  }

  th,
  td {
    border: solid 1px var(--ion-color-lightgrey);
    padding: 0 5px;
    font-size: 0.80rem;

    ion-checkbox {
      padding: 10px;
    }

    // .badge {
    //   background-color: var(--ion-color-danger);
    //   color: var(--ion-color-light);
    //   padding: 4px 2px;
    //   text-align: center;
    //   border-radius: 100%;
    //   font-size: 11px;
    //   margin-right: 2px;
    // }

    .status {
      img {
        width: 6px;
        height: 6px;
      }
    }

    .uploads {
      margin-right: 10px;

      img {
        width: 12px;
        height: 12px;
      }
    }


    input[type="radio"] {
      width: 3rem;
      height: 1.5em;
    }

    input[type="radio"]:checked {
      accent-color: #4a87e0;
    }
  }

  /*  td:first-child {
    max-width:2.5rem;
    justify-items:center;
   padding-right:3px
  }*/

  .jobtitle {
    img {
      float: right;
      width: 18px;
      height: 18px;
    }
  }
}


.paginator-container {
  margin-top: 1.25em;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    color: var(--ion-color-secondary);
    border-radius: 0 !important;
    padding: 10px;
  }
}

.full-height-card {
  height: 100%;
  display: flex;
  flex-direction: column;
}

ion-card-content {
  flex: 1;
  overflow-y: auto; // Enable scrolling for content inside the card if it overflows
}


.card {
  display: flex;
  align-items: center;
  justify-content: center;


  ion-card {
    padding: 5px;
    min-width: 80vw;
    //max-width: 80vw;
    min-height: 35vh;
    /* max-height: 45vh;*/
    align-items: center;
    justify-content: center;
    padding: 0.3rem;
  }

  .addPadding {
    padding: 2px;
  }

  .edit {
    text-transform: unset;


    ion-img {
      padding-right: 1vw;
    }
  }

  .header {
    padding-left: 20px;
    font-weight: bold;
  }
}

ion-button {
  text-transform: none;

  &.edit {
    width: 8rem;
    text-transform: none;

    .edit-icon-img {
      text-align: start;
      left: 0;
      position: absolute;
    }
  }

  &.confirm {
    text-transform: none;
  }

  &.cancel {
    width: 8rem;
    background-color: white;
    color: #EE2424;
    border-radius: 0.5rem;
    border-color: #EE2424;
    border-style: solid;
    border-width: 1px;
    box-shadow: none;
    text-transform: none;

    button.button-native {
      box-shadow: none;
    }
  }
}

.row-hover-highlight {

  &:hover {
    background-color: var(--ion-color-fadeblue);;

    cursor: pointer;

    ion-item {
      --background: var(--ion-color-fadeblue);;
    }
  }
}


ion-button {
  text-transform: none;

  &.modal-edit {
    width: 10rem;
    text-transform: none;

    .edit-icon-img {
      text-align: start;
      left: 0;
      position: absolute;
    }
  }

  &.modal-confirm {
    width: 10rem;
    text-transform: none;
  }

  &.modal-cancel {
    width: 10rem;
    background-color: white;
    color: #EE2424;
    border-radius: 0.5rem;
    border-color: #EE2424;
    border-style: solid;
    border-width: 1px;
    box-shadow: none;
    text-transform: none;

    button.button-native {
      box-shadow: none;
    }
  }
}

.media-action-modal {
  align-items: center;
  justify-content: center;
  ion-card {
    padding: 5px;

  }

  &::part(content) {
    max-height: 80vh;
    width: 35vw;
    height: 250px;
  }
}

.upload-capture-modal {
  align-items: center;
  justify-content: center;

  ion-card {
    padding: 5px;

  }

  &::part(content) {
    max-height: 80vh;
    width: 42vw;
    height: auto;
  }
}


.user-details-modal {
  align-items: center;
  justify-content: center;

  ion-card {
    padding: 5px;

    ion-button {
      &.edit {
        width: 8rem;

        .edit-icon-img {
          text-align: start;
          left: 0;
          position: absolute;
        }

        &.confirm {}
      }

      &.cancel {
        width: 8rem;
        background-color: white;
        color: #EE2424;
        border-radius: 0.5rem;
        border-color: #EE2424;
        border-style: solid;
        border-width: 1px;
        box-shadow: none;
        text-transform: none;

        button.button-native {
          box-shadow: none;
        }
      }
    }
  }


  &::part(content) {
    max-height: 80vh;
    width: 60vw;
    height: auto;
  }
}

.fullscreen-modal {
  --width: 100vw;
  --height: 100vh;
}

.pa-dialog-container {
  padding: 15px;
}

.user-role-modal {
  align-items: center;
  justify-content: center;
  border: #5499ff;

  ion-card {
    padding: 5px;

    ion-button {
      &.edit {
        width: 8rem;

        .edit-icon-img {
          text-align: start;
          left: 0;
          position: absolute;
        }

        &.confirm {}
      }

      &.cancel {
        width: 8rem;
        background-color: white;
        color: #EE2424;
        border-radius: 0.5rem;
        border-color: #EE2424;
        border-style: solid;
        border-width: 1px;
        box-shadow: none;
        text-transform: none;

        button.button-native {
          box-shadow: none;
        }
      }
    }
  }


  &::part(content) {
    max-height: 30vh;
    width: 30vw;
    height: auto;
  }
}


.pa-dialog-container {
  padding: 15px;
}

.job-details-modal {
  align-items: center;
  justify-content: center;

  ion-card {
    padding: 5px;

    ion-button {
      &.edit {
        width: 8rem;

        .edit-icon-img {
          text-align: start;
          left: 0;
          position: absolute;
        }

        &.confirm {}
      }

      &.cancel {
        width: 8rem;
        background-color: white;
        color: #EE2424;
        border-radius: 0.5rem;
        border-color: #EE2424;
        border-style: solid;
        border-width: 1px;
        box-shadow: none;
        text-transform: none;

        button.button-native {
          box-shadow: none;
        }
      }
    }
  }


  &::part(content) {
    width: 85vw;
    height: 85vh;
  }
}

.customer-details-modal,
.object-builder-modal {
  align-items: center;
  justify-content: center;

  ion-card {
    padding: 5px;

    ion-button {
      &.edit {
        width: 8rem;

        .edit-icon-img {
          text-align: start;
          left: 0;
          position: absolute;
        }

        &.confirm {}
      }

      &.cancel {
        width: 8rem;
        background-color: white;
        color: #EE2424;
        border-radius: 0.5rem;
        border-color: #EE2424;
        border-style: solid;
        border-width: 1px;
        box-shadow: none;
        text-transform: none;

        button.button-native {
          box-shadow: none;
        }
      }
    }
  }

  &::part(content) {
    width: 70vw;
    height: 35vw;
  }
}

.template-details-modal {
  align-items: center;
  justify-content: center;

  ion-card {
    padding: 5px;

    ion-button {
      &.edit {
        width: 8rem;

        .edit-icon-img {
          text-align: start;
          left: 0;
          position: absolute;
        }

        &.confirm {}
      }

      &.cancel {
        width: 8rem;
        background-color: white;
        color: #EE2424;
        border-radius: 0.5rem;
        border-color: #EE2424;
        border-style: solid;
        border-width: 1px;
        box-shadow: none;
        text-transform: none;

        button.button-native {
          box-shadow: none;
        }
      }
    }
  }

  &::part(content) {
    width: 40rem;
    height: auto;
  }
}

.template-assets-modal {
  align-items: center;
  justify-content: center;

  ion-card {
    padding: 5px;

    ion-button {
      &.edit {
        width: 8rem;

        .edit-icon-img {
          text-align: start;
          left: 0;
          position: absolute;
        }

        &.confirm {}
      }

      &.cancel {
        width: 8rem;
        background-color: white;
        color: #EE2424;
        border-radius: 0.5rem;
        border-color: #EE2424;
        border-style: solid;
        border-width: 1px;
        box-shadow: none;
        text-transform: none;

        button.button-native {
          box-shadow: none;
        }
      }
    }
  }

  &::part(content) {
    width: 100rem;
    height: 50rem;
  }
}

.project-details-modal {
  align-items: center;
  justify-content: center;

  ion-card {
    padding: 5px;

    ion-button {
      &.edit {
        width: 8rem;

        .edit-icon-img {
          text-align: start;
          left: 0;
          position: absolute;
        }

        &.confirm {}
      }

      &.cancel {
        width: 8rem;
        background-color: white;
        color: #EE2424;
        border-radius: 0.5rem;
        border-color: #EE2424;
        border-style: solid;
        border-width: 1px;
        box-shadow: none;
        text-transform: none;

        button.button-native {
          box-shadow: none;
        }
      }
    }
  }

  &::part(content) {
    width: 70vw;
    height: auto;
  }
}

.object-media-item-modal {
  align-items: center;
  justify-content: center;

  ion-card {
    padding: 5px;

    ion-button {
      &.edit {
        width: 8rem;

        .edit-icon-img {
          text-align: start;
          left: 0;
          position: absolute;
        }

        &.confirm {}
      }

      &.cancel {
        width: 8rem;
        background-color: white;
        color: #EE2424;
        border-radius: 0.5rem;
        border-color: #EE2424;
        border-style: solid;
        border-width: 1px;
        box-shadow: none;
        text-transform: none;

        button.button-native {
          box-shadow: none;
        }
      }
    }
  }

  &::part(content) {
    width: 70vw;
    height: auto;
  }
}

.validator-error {
  color: red;
  margin-left: 16px;
}

.mandatory-field-mark {
  font-weight: bold;
  color: red;
}

.info-snackbar {
  background-color: #333;
  color: white;
}

.success-snackbar {
  background-color: #568A2C;
  color: white;
}

.error-snackbar {
  background-color: #AF3033;
  color: white;
}

ion-modal.modal-default.show-modal:last-of-type {
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

//Job Statuses state
.Archived {
  ion-button {
    background: var(--ion-color-primary);
    color: #000000;

    ion-icon {
      color: #ffffff;
    }
  }

  ion-icon {
    color: var(--ion-color-primary);
  }
}

.Closed {
  ion-button {
    background: var(--ion-color-primary);
    color: #000000;

    ion-icon {
      color: #ffffff;
    }
  }

  ion-icon {
    color: var(--ion-color-danger);
  }
}

.Approved,
.Started,
.Submitted {
  ion-icon {
    color: var(--ion-color-success);
  }
}

.Assigned {
  ion-icon {
    color: var(--ion-color-warning);
  }
}

.Created {
  ion-icon {
    color: var(--ion-color-secondary);
  }
}

.Draft {
  ion-icon {
    color: var(--ion-color-medium);
  }
}

.square-btn {
  height: 50px;
  width: 50px;
}

ion-segment-button {
  color: var(--ion-color-primary);
  --background-checked:  var(--ion-color-secondary, #0163aa) !important;
  min-height: 2.5rem;
  height: 2.5rem;
  --indicator-color: transparent;
  margin: 0.3rem 0 0.3rem 0.3rem;
}

.segment-button-checked {
  ion-label {
      color: #FFF;
  }
}

/**
* Ion Modals
* -----------------------------------------------------
* These are the style for the modals
*/

.modal-outer {
  align-items: center;
  justify-content: center;

  &::part(content) {
    width: 85vw;
    height: 85vh;
  }

}

.shortened-content-modal-outer {
  align-items: center;
  justify-content: center;

  &::part(content) {
    width: 50vw;
    height: 45vh;
  }
}

.modal-header{
  padding: 1% 2%;

  ion-title {
    padding: 1% 0;
    font-size: 1.4rem;
  }
}

.modal-scrollable-content-container{
  overflow-y: auto;
  --offset-bottom: auto !important;


  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: var(--ion-color-lightgrey);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--ion-color-secondary);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--ion-color-secondary-shade)
  }
  
  .main-content {
    padding: 1% 2%;  
  }
  
}

.modal-footer {
  
  .action-buttons-container {
    padding-right: 2%;
    ion-button {
      margin-right: 2%;
      min-width: 12%;
    }      
  }
}

ion-action-sheet.unsaved-changes-warning {

  .action-sheet-title.sc-ion-action-sheet-ios {
    font-size: 1.3rem;
    color: red;
  }

  .action-sheet-wrapper.sc-ion-action-sheet-ios {
    top: 35%;
    bottom: auto !important;
  }
}


@media (max-width:960px) and (min-width:768px) {}


@media (max-width:1280px) and (min-width:961px) {}


@media (max-width:1300px) and (min-width:1281px) {}

//(max-width:1366px) and
@media (min-width:1301px) {}