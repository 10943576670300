// For information on how to create your own theme, please see:
// http://ionicframework.com/docs/theming/
:root {
  /*--ion-color-primary: #020b55;
  --ion-color-primary-rgb: 2,11,85;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #020a4b;
  --ion-color-primary-tint: #1b2366;
  --ion-color-secondary: #06a1ef;
  --ion-color-secondary-rgb: 6,161,239;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #058ed2;
  --ion-color-secondary-tint: #1faaf1;
  --ion-color-tertiary: #6030ff;
  --ion-color-tertiary-rgb: 96,48,255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #542ae0;
  --ion-color-tertiary-tint: #7045ff;
  --ion-color-success: #568A2C;
  --ion-color-success-rgb: 86,138,44;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #28bb50;
  --ion-color-success-tint: #42d96b;
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255,196,9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;
  --ion-color-danger: #c5000f;
  --ion-color-danger-rgb: 197,0,15;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #ad000d;
  --ion-color-danger-tint: #cb1a27;
  --ion-color-light: #f6f8fc;
  --ion-color-light-rgb: 246,248,252;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d8dade;
  --ion-color-light-tint: #f7f9fc;
  --ion-color-medium: #5f5f5f;
  --ion-color-medium-rgb: 95,95,95;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #545454;
  --ion-color-medium-tint: #6f6f6f;
  --ion-color-dark: #2f2f2f;
  --ion-color-dark-rgb: 47,47,47;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #292929;
  --ion-color-dark-tint: #444444;
*/
  //Primary
  --ion-color-primary: #212652;
  --ion-color-primary-rgb: 33,38,82;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #1d2148;
  --ion-color-primary-tint: #373c63;
  //Secondary
  --ion-color-secondary: #359DF2;
  --ion-color-secondary-rgb: 53,157,242;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #2f8ad5;
  --ion-color-secondary-tint: #49a7f3;
  //Tertiary

  --ion-color-tertiary: #54d9ff;
  --ion-color-tertiary-rgb: 84,217,255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #4abfe0;
  --ion-color-tertiary-tint: #65ddff;
  //Medium
  --ion-color-medium: #99A0B7;
  --ion-color-medium-rgb: 153,160,183;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #878da1;
  --ion-color-medium-tint: #a3aabe;
  //Dark
  --ion-color-dark: #231F20;
  --ion-color-dark-rgb: 35,31,32;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #1f1b1c;
  --ion-color-dark-tint: #393536;
  //Light Grey

  --ion-color-lightgrey: #e1e3e5;
  --ion-color-lightgrey-rgb: 225,227,229;
  --ion-color-lightgrey-contrast: #231F20;
  --ion-color-lightgrey-contrast-rgb: 255,255,255;
  --ion-color-lightgrey-shade: #c6c8ca;
  --ion-color-lightgrey-tint: #e4e6e8;
  //Purple
  --ion-color-purple: #9e6bf7;
  --ion-color-purple-rgb: 158,107,247;
  --ion-color-purple-contrast: #ffffff;
  --ion-color-purple-contrast-rgb: 255,255,255;
  --ion-color-purple-shade: #8b5ed9;
  --ion-color-purple-tint: #a87af8;
  //Lightblue
  --ion-color-lightblue: #27C3EF;
  --ion-color-lightblue-rgb: 39,195,239;
  --ion-color-lightblue-contrast: #000000;
  --ion-color-lightblue-contrast-rgb: 0,0,0;
  --ion-color-lightblue-shade: #22acd2;
  --ion-color-lightblue-tint: #3dc9f1;
  //Fadeblue
  --ion-color-fadeblue: #E5F8FE;
  --ion-color-fadeblue-rgb: 229,248,254;
  --ion-color-fadeblue-contrast: #000000;
  --ion-color-fadeblue-contrast-rgb: 0,0,0;
  --ion-color-fadeblue-shade: #cadae0;
  --ion-color-fadeblue-tint: #e8f9fe;
  //Success
  --ion-color-success: #568A2C;
  --ion-color-success-rgb: 86,138,44;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #28bb50;
  --ion-color-success-tint: #42d96b;
  //Warning
  --ion-color-warning: #FFA500;
  --ion-color-warning-rgb: 255,196,9;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255,255,255;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;
  //Danger
  --ion-color-danger: #FF6347;
  --ion-color-danger-rgb: 197,0,15;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #ad000d;
  --ion-color-danger-tint: #cb1a27;
}

.ion-color-lightgrey {
  --ion-color-base: var(--ion-color-lightgrey);
  --ion-color-base-rgb: var(--ion-color-lightgrey-rgb);
  --ion-color-contrast: var(--ion-color-lightgrey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lightgrey-contrast-rgb);
  --ion-color-shade: var(--ion-color-lightgrey-shade);
  --ion-color-tint: var(--ion-color-lightgrey-tint);
}



.ion-color-purple {
  --ion-color-base: var(--ion-color-purple);
  --ion-color-base-rgb: var(--ion-color-purple-rgb);
  --ion-color-contrast: var(--ion-color-purple-contrast);
  --ion-color-contrast-rgb: var(--ion-color-purple-contrast-rgb);
  --ion-color-shade: var(--ion-color-purple-shade);
  --ion-color-tint: var(--ion-color-purple-tint);
}


.ion-color-lightblue {
  --ion-color-base: var(--ion-color-lightblue);
  --ion-color-base-rgb: var(--ion-color-lightblue-rgb);
  --ion-color-contrast: var(--ion-color-lightblue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lightblue-contrast-rgb);
  --ion-color-shade: var(--ion-color-lightblue-shade);
  --ion-color-tint: var(--ion-color-lightblue-tint);
}


.ion-color-fadeblue {
  --ion-color-base: var(--ion-color-fadeblue);
  --ion-color-base-rgb: var(--ion-color-fadeblue-rgb);
  --ion-color-contrast: var(--ion-color-fadeblue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-fadeblue-contrast-rgb);
  --ion-color-shade: var(--ion-color-fadeblue-shade);
  --ion-color-tint: var(--ion-color-fadeblue-tint);
}


.ion-color-medium {
  --ion-color-base: var(--ion-color-medium);
  --ion-color-base-rgb: var(--ion-color-medium-rgb);
  --ion-color-contrast: var(--ion-color-medium-contrast);
  --ion-color-contrast-rgb: var(--ion-color-medium-contrast-rgb);
  --ion-color-shade: var(--ion-color-medium-shade);
  --ion-color-tint: var(--ion-color-medium-tint);
}
